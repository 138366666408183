import styles from './image-banner.module.css';

const ImageBanner = ({imageBanner}) => {
    const imageBannerContent = imageBanner ?
        <div className={styles.bannerDiv}>
            <a
                className={styles.imageBannerLink}
                href={imageBanner.destinationUrl}
            >
                <picture>
                    {
                        imageBanner.desktopImageUrl &&
                            <source
                                media="(min-width: 650px)"
                                srcSet={imageBanner.desktopImageUrl}
                            />
                    }
                    {
                        imageBanner.mobileImageUrl &&
                            <img
                                alt={imageBanner.imageAltText}
                                className={styles.bannerImage}
                                src={imageBanner.mobileImageUrl}
                            />
                    }
                </picture>
            </a>
        </div>
        : null;

    return imageBannerContent;

};

export default ImageBanner;
