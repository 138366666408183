import {connect} from 'react-redux';

import withRedux from '../../utils/with-redux';
import withMixedAuthenticationCheck from '../../utils/with-mixed-authentication-check';
import LandingView from '../../views/landing';
import HeaderPage from '../../components/header-page';

const title = 'Hy-Vee PERKS Plus Membership';
const description =
    'Become a member to get FREE delivery and 2-hour express pickup, everyday fuel savings, exclusive perks, PERKS prices, and more!';

const seo = {
    additionalMetaTags: [
        {
            className: 'swiftype',
            content: description,
            'data-type': 'text',
            name: 'body',
        },
    ],
    description,
    openGraph: {
        description,
        images: [
            {
                alt: title,
                height: 627,
                url:
                    'https://76c35e76589404e46312-6ec6d4c514ba6d410de8bce9cae9c292.ssl.cf2.rackcdn.com/H-PLUS_Open%20Graph_1200x627.jpg',
                width: 1200,
            },
        ],
        // eslint-disable-next-line camelcase
        site_name: 'Hy-Vee',
        title,
        url: 'https://hy-vee.com/plus',
    },
    title,
};

const MembershipSplashPage = ({customer}) => (
    <HeaderPage
        customer={customer}
        seo={seo}
    >
        <LandingView />
    </HeaderPage>
);

const mapStateToProps = (state) => ({
    customer: state.customer,
});

export default withRedux(withMixedAuthenticationCheck(connect(mapStateToProps)(MembershipSplashPage)));
