// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Button, SecondaryButton} from '@hy-vee/design-system';

import styles from './call-to-action.module.css';

const CallToAction = ({callToAction, toggleDeliveryModal}) => (
    <div className={styles.callToAction}>
        <div className={styles.callToActionContentContainer}>
            <h3>{callToAction.heading}</h3>
            {
                callToAction.subHeading && <h4>{callToAction.subHeading}</h4>
            }
            <p>{callToAction.text}</p>

            <div className={styles.callToActionButtons}>
                {
                    callToAction.actionButtons.map((button, index) => {
                        const onClick = (event) => {
                            if (button.function === 'DELIVERY_CHECKER') {
                                toggleDeliveryModal(event);
                            } else if (button.function === 'LINK' && button.destination) {
                                window.location.href = button.destination;
                            }
                        };

                        const Component = button.primary ? Button : SecondaryButton;

                        return (
                            <Component
                                data-testid="action-button"
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={`action-button-${index}`}
                                onClick={onClick}
                            >
                                {button.text}
                            </Component>
                        );
                    })
                }
            </div>
        </div>
        <div className={styles.callToActionImageContainer}>
            <img
                alt={callToAction.image.alt}
                data-testid="call-to-action-image"
                src={callToAction.image.src}
            />
        </div>
    </div>
);

export default CallToAction;
