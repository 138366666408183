// eslint-disable-next-line no-unused-vars
import React from 'react';

const AuthIframe = () => <iframe
    aria-hidden
    src="/plus/api/keycloak-iframe"
    style={{
        display: 'none',
    }}
    title="Hidden Auth IFrame" />;

export default AuthIframe;
