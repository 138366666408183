import BlockContent from '@sanity/block-content-to-react';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import styles from './benefit-cards.module.css';

const BenefitCards = ({membershipBenefitCards, toggleDeliveryModal}) => (
    <div className={styles.benefitCards}>
        {
            membershipBenefitCards.map((card, index) => (

                <div
                    className={styles.benefitCard}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`benefit-card-${index}`}
                    style={membershipBenefitCards.length < 3 ? {
                        flexBasis: '49%',
                    } : {}}
                >
                    <div className={styles.benefitCardMainContentContainer}>
                        <div>
                            <img
                                alt="Plus Logo"
                                src="https://808dde4744a73b71b1e3-8812790c7feaac6b6f6577276d42f086.ssl.cf2.rackcdn.com/Plus-Large.svg"
                            />
                        </div>
                        <div>
                            <h2>{card.heading}</h2>
                            <div className={styles.content}>
                                <BlockContent blocks={card.contentRaw} />
                            </div>
                            <p className={styles.savingsText}>{card.savingsText}</p>
                            <p className={styles.savingsSubText}>{card.savingsSubText}</p>
                            <LinkButton
                                as="a"
                                data-testid="action-link"
                                onClick={(event) => {
                                    if (card.actionLinkFunction === 'DELIVERY_CHECKER') {
                                        toggleDeliveryModal(event);
                                    } else if (card.actionLinkFunction === 'LINK' && card.actionLinkDestination) {
                                        window.location.href = card.actionLinkDestination;
                                    }
                                }}
                            >
                                {card.actionLinkText}
                            </LinkButton>
                        </div>
                    </div>
                    <div className={styles.testimonialContainer}>
                        <div className={styles.testimonialLeftQuote}>
                            &ldquo;
                        </div>
                        <div className={styles.testimonial}>
                            <BlockContent blocks={card.testimonialContentRaw} />
                        </div>
                        <div className={styles.testimonialRightQuote}>
                            &rdquo;
                        </div>
                        <p className={styles.testimonialName}>
                            {'– '}
                            {card.testimonialName}
                        </p>
                    </div>

                </div>
            ))
        }
    </div>
);

export default BenefitCards;
