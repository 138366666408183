// eslint-disable-next-line no-unused-vars
import React from 'react';

import styles from './Image.module.css';

const SanityImage = ({alt, webpSrc2x, webpSrc1x, jpegSrc2x, jpegSrc1x}) =>
    <picture
        data-testid="image"
    >
        {
            webpSrc2x &&
                <source
                    srcSet={`${webpSrc2x} 2x`}
                    type="image/webp"
                />
        }
        {
            webpSrc1x &&
                <source
                    srcSet={`${webpSrc1x} 1x`}
                    type="image/webp"
                />
        }
        {
            jpegSrc2x &&
                <source
                    srcSet={`${jpegSrc2x} 2x`}
                    type="image/jpeg"
                />
        }
        {
            jpegSrc1x &&
                <source
                    srcSet={`${jpegSrc1x} 1x`}
                    type="image/jpeg"
                />
        }
        {
            jpegSrc1x &&
                <img
                    alt={alt}
                    className={styles.image}
                    src={jpegSrc1x}
                />
        }
    </picture>;

export default SanityImage;
