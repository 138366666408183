// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled, {css} from 'styled-components';
import {breakpoints} from '@hy-vee/themes';

import FocusedContent from './focused-content';

const StyledPageContainer = styled.div`
    ${({defaultMargin}) =>
        defaultMargin
            ? css`
                  margin: 0;
                  min-height: 300px;

                  @media (min-width: ${breakpoints.small}) {
                      min-height: 600px;
                  }
              `
            : css`
                  margin: 0;
              `};

    ${({padding}) =>
        padding &&
    css`
            padding: 0 20px;
        `};
`;

const PageContainer = ({children, maxwidth, defaultMargin = true, padding = false}) => (
    <StyledPageContainer
        data-iframe-height
        defaultMargin={defaultMargin}
        padding={padding}
    >
        <FocusedContent maxwidth={maxwidth}>{children}</FocusedContent>
    </StyledPageContainer>
);

export default PageContainer;
