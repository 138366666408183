// eslint-disable-next-line no-unused-vars
import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import styles from './styled-block-content.module.css';

const StyledBlockContent = ({blocks}) => (
    <div className={styles.styledBlockContent}>
        <BlockContent blocks={blocks} />
    </div>
);

export default StyledBlockContent;
