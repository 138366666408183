import StyledBlockContent from '../../shared/styled-block-content';

import styles from './footer.module.css';

const Footer = ({content}) => (
    <div className={styles.footer}>
        <StyledBlockContent blocks={content} />
    </div>
);

export default Footer;
